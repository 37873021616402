export const pages = [{
    name: 'Home',
    link: '/'
},
{
    name: 'About Me',
    link: '/about'
},
{
    name: 'Career Timeline',
    link: '/timeline'
},
]